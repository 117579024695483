import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFoundView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/adventurer_list',
    name: 'adventurer_list',
    component: () => import('../views/AdventurerListView.vue')
    // component: CharactersView
  },
  {
    path: '/adventurer/:id',
    name: 'adventurer',
    props: true,
    component: () => import('../views/AdventurerView.vue')
  },
  // {
  //   path: '/report_adv/:id',
  //   name: 'report_adv',
  //   props: true,
  //   component: () => import('../views/ReportAdvView.vue')
  // },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  // {
  //   path: '/gacha/',
  //   name: 'gacha',
  //   props: true,
  //   component: () => import('../views/GachaView.vue')
  // },
  {
    path: '/filter_adv',
    name: 'filter_adv',
    props: true,
    component: () => import('../views/FilterAdvView.vue')
  },
  {
    path: '/dispatch',
    name: 'dispatch',
    props: true,
    component: () => import('../views/DispatchQuestView.vue')
  },
  {
    path: '/assist_list',
    name: 'assist_list',
    props: true,
    component: () => import('../views/AssistListView.vue')
  },
  {
    path: '/assist/:id',
    name: 'assist',
    props: true,
    component: () => import('../views/AssistView.vue')
  },
  {
    path: '/filter_ast',
    name: 'filter_ast',
    props: true,
    component: () => import('../views/FilterAstView.vue')
  },
  //redirect
  {
    path: '/adventurers',
    redirect: '/adventurer_list'
  },
  //catch 404
  {
    path: '/:catchAll(.*)',
    // path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes: routes
})

export default router
