<template>
    <Navbar />
    <router-view/>
</template>

<script>
import Navbar from '../src/components/Navbar.vue'

export default {
  components: { Navbar }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  /* text-align: center;
  color: #2c3e50; */
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

/* .header {
  margin: 0;
} */

/* nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
