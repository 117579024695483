import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import {
  create,
  NButton,
  NForm,
  NFormItem,
  NInput 
} from 'naive-ui'

const naive = create({
  components: [
    NButton, NForm, NFormItem, NInput
  ]
})

const app = createApp(App)

app.use(naive)

app.use(router).mount('#app')
