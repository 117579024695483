<template>
  <!-- <nav> -->
    <div class="topbar">
      <div id="logo" @click="returnHome">
        <img alt="money" src="../assets/potato.png">
      </div>
      <div id="logoText" @click="returnHome">
        <p>Danmemo Toolbox</p>
      </div>

      <div id="logoandText" @click="returnHome">
        <img alt="money" src="../assets/potato.png">
        <p>Danmemo Toolbox</p>
      </div>
      <!-- <div class="donate" @click="showModalDonate = true">
        <img alt="money" src="../assets/gem300.png">
      </div> -->
      <button class="drawer" @click="showDrawer = true">
      </button>

      <div id="dropdownGroup">
        <div id="dropdownSection" @mouseleave="exitDropdown()">
          <div id="dropdownSelect" @click="handleDropdown(0)">
              Char. Info.
          </div>
          <Transition name="dropdownTransition">
            <div id="dropdownDrop" v-if="dropdownDrop[0]">
              <n-card id="dropdownCard" size="small" @click="goPage('adventurer_list')">Adventurers List</n-card>
              <n-card id="dropdownCard" size="small" @click="goPage('filter_adv')">Adv. Filter</n-card>
              <n-card id="dropdownCard" size="small" @click="goPage('assist_list')">Assist List</n-card>
              <n-card id="dropdownCard" size="small" @click="goPage('filter_ast')">Assist Filter</n-card>
            </div>
          </Transition>
        </div>
        <div id="dropdownSection" @mouseleave="exitDropdown()">
          <div id="dropdownSelect" @click="handleDropdown(1)">
              Tools
          </div>
          <Transition name="dropdownTransition">
            <div id="dropdownDrop" v-if="dropdownDrop[1]">
              <n-card id="dropdownCard" size="small" @click="goPage('dispatch')">Dispatch Quest</n-card>
              <!-- <n-card id="dropdownCard" size="small">2</n-card> -->
            </div>
          </Transition>
        </div>
        <div id="dropdownSection" @mouseleave="exitDropdown()">
          <div id="dropdownSelect" @click="handleDropdown(2)">
              Extra
          </div>
          <Transition name="dropdownTransition">
            <div id="dropdownDrop" v-if="dropdownDrop[2]">
              <n-card id="dropdownCard" size="small" @click="goPage('contact')">Contact Info</n-card>
              <!-- <n-card id="dropdownCard" size="small">2</n-card> -->
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <n-drawer v-model:show="showDrawer"
      width="30%"
      id="drawerModal"
      show-mask="transparent"
      placement="right"
    >
      <!-- <n-drawer-content title="Characters Information" header-style="color: white"> -->
      <n-divider title-placement="Center" id="divider_card">Adventurer Information</n-divider>

      <div id="hexagon" @click="goPage('adventurer_list')">
        <div id="hexagon_intern">
          Adventurers List
        </div>
      </div>
      <div id="hexagon" @click="goPage('filter_adv')">
        <div id="hexagon_intern">
          Adv. Filter
        </div>
      </div>

      <n-divider title-placement="Center" id="divider_card">Assist Information</n-divider>

      <div id="hexagon" @click="goPage('assist_list')">
        <div id="hexagon_intern">
          Assist List
        </div>
      </div>
      <div id="hexagon" @click="goPage('filter_ast')">
        <div id="hexagon_intern">
          Ast. Filter
        </div>
      </div>

      <n-divider title-placement="Center" id="divider_card">Dispatch Quest</n-divider>

      <div id="hexagon" @click="goPage('dispatch')">
        <div id="hexagon_intern">
          Dispatch Quest
        </div>
      </div>

      <n-divider title-placement="Center" id="divider_card">Extra</n-divider>

      <div id="hexagon" @click="goPage('contact')">
        <div id="hexagon_intern">
          Contact Info
        </div>
      </div>

      <!-- </n-drawer-content> -->
      <div id="infoVersion">
        <p>v1.0.0</p>
        <p>made by Bravesa#6596</p>
      </div>
    </n-drawer>
    <n-modal v-model:show="showModalDonate">
      <n-card
      style="width: 600px"
      title="Modal"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
      >
        <template #header-extra>
          Oops!
        </template>
        Content
        <template #footer>
          Footer
        </template>
      </n-card>
    </n-modal>
  <!-- </nav> -->
</template>

<script>
import {  
  NModal,
  NCard,
  NDropdown,
  NButton,
  NDrawer,
  NDrawerContent,
  NDivider
} from 'naive-ui'
import {ref} from 'vue'

export default {
  setup(){
    return{
      showModalDonate: ref(false),
      showDrawer: ref(false),

      dropdownDrop: ref([false, false, false]),

      charDropdown: null
    }
  },
  components:{
    NModal,
    NCard,
    NDropdown,
    NButton,
    NDrawer,
    NDrawerContent,
    NDivider
  },
  beforeMount(){
    var DropdownInfo = require('../composables/constants/dropdown_navbar.json')

    this.charDropdown = DropdownInfo
  },
  methods:{
    returnHome(){
      this.$router.push({name: 'home'})
    },
    goPage(url){
      this.exitDropdown()
      this.showDrawer = false
      this.$router.push({name: url})
    },
    handleDropdown (key) {
      for(let i = 0; i < this.dropdownDrop.length; i++){
        this.dropdownDrop[i] = false
      }
      this.dropdownDrop[key] = !this.dropdownDrop[key]
    },
    exitDropdown(){
      for(let i = 0; i < this.dropdownDrop.length; i++){
        this.dropdownDrop[i] = false
      }
    }
    // gotoReport() {
    //   this.$router.push({ name: "report_adv" });
    // },
  }
}
</script>

<style>
  .topbar {
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0) 5%, #242424 20%, #242424 68%, #242424 100%); */
    background: #eae9e5;
    /* background: #e0dfdb; */
    width: 100%;
    height: 74px; /* + 20 do gradiente */
    position: absolute;
    top: 0px;
    box-shadow: 0px 0px 4px 5px rgba(31, 31, 31, 0.6);
    z-index: 2;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    vertical-align: middle;
  }

  #logo {
    /* color: white; */
    cursor: pointer;
    width: auto;
    height: 74px;
    /* font-weight: bold;
    font-size: 20px; */
    /* margin-left: 4%; */
    left: 0px;
    padding: 0px 4%;
    display: inline-block;
    position: absolute;
    /* background-color: brown; */
  }
  #logo img{
    float: left;
    object-fit: fill;
    width: 60px;
    margin-top: 4px;
  }
  #logo p{
    margin-top: 22px;
    transition: 0.3s;
  }

  #logoandText {
    color: black;
    cursor: pointer;
    width: 240px;
    height: 74px;
    font-weight: bold;
    font-size: 20px;
    left: 0px;
    padding: 0px 3% 0px 2%;
    display: none;
    position: absolute;
  }
  #logoandText img{
    float: left;
    object-fit: fill;
    width: 60px;
    margin-top: 4px;
  }
  #logoandText p{
    margin-top: 22px;
    transition: 0.3s;
  }

  #logoText {
    color: black;
    /* background-color: gray; */
    height: 100%;
    font-weight: bold;
    font-size: 20px;
    /* margin-top: 22px; */
    padding: 0px 10px;
    transition: 0.2s;
    visibility: visible;
    opacity: 1;
  }


  .donate{
    color: black;
    background: #92E7FF;
    padding: 0px 10px;
    border-radius: 5px;
    right: 2%;
    top: 10%;
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: table;
    opacity: 0.9;
    transition: 0.3s;
  }
  .donate:hover{
    opacity: 1;
    box-shadow: 0px 0px 16px 3px #92E7FF;
  }
  .donate p{
    float: right;
    font-weight: bold;
    margin-top: 18px;
    margin-right: 10px;
    color: black;
  }

  .donate img{
    width: 50px;
    object-fit: fill;
  }

  .drawer{
    /* color: black; */
    background: #e0dfdb;
    padding: 0px 10px;
    border: #D1D0CC 2px solid;
    border-radius: 3px;
    right: 2%;
    top: 10%;
    position: absolute;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: table;
    transition: all 0.15s;
    background-image: url('../assets/barsDrawer.png');
  }
  .drawer:hover{
    /* border: #858585; */
    /* box-shadow: 0px 5px 13px -7px #757575; */
    background-color: #C9C8C5;
  }
  .drawer:active{
    box-shadow: inset 0px 0px 10px 3px #B7B7B5;
    transform: translateY(1px);
  }

  #divider_card{
    color: #1f1f1f;
    font-size: 16px;
    padding: 0px 4px;
  }
  #divider_card div{
    --n-color: #1f1f1f;
  }

  #drawer_card{
    height: 60px;
    width: calc(100% - 52px);
    margin-bottom: 8px;
    margin-left: 26px;
    /* margin-left: 16px; */
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border: 1px gray solid;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.15s;
  }
  #drawer_card:hover{
    box-shadow: inset 0px 0px 10px 2px #92E7FF;
  }

  #drawerModal{
    /* width: 260px; */
    min-width: 260px;
    margin-top: 74px; 
    background-color: #eae9e5;
    transition: 0.3s;
  }

  #dropdownGroup{
    height: 100%;
    width: calc(100% - 240px - 5%);
    display: none;
    position: absolute;
    right: 0px;
  }
  
  #dropdownSection{
    width: 20%;
    height: 100%;
    max-width: 200px;
    min-width: 150px;
    position: relative;
    /* background-color: brown; */
    
  }

  #dropdownSelect{
    width: 100%;
    height: calc(100% - 2px);
    /* background-color: #e0dfdb; */
    background: linear-gradient(180deg, rgba(224,223,219,1) 0%, rgba(211,210,207,1) 100%);
    border: #D1D0CC 2px solid;
    border-top: 0px;
    position: absolute;
    cursor: pointer;
    transition: 0.1s;
    z-index: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-weight: bold;
    text-align: center;
  }

  #dropdownSelect:hover{
    box-shadow: 0px 0px 10px 2px white;
    border-bottom: white;
    z-index: 8;
  }

  #dropdownSelect:active{
    transform: translateY(2px);
  }

  #dropdownDrop{
    position: relative;
    width: calc(100% - 2px);
    margin-top: 74px;
    padding-bottom: 16px;
    height: auto;
    background-color: #e0dfdb;
    border: #D1D0CC 2px solid;
    border-top: 0px;
    border-radius: 4px;
    z-index: 3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    /* translate: -2px; */
    /* background-color: brown; */
    /* display: none; */
  }

  #dropdownCard{
    /* height: 48px; */
    width: 94%;
    left: 3%;
    margin-top: 4px;
    top: 10px;
    display: flex;
    vertical-align: center;
    cursor: pointer;
  }

  #dropdownCard:hover{
    box-shadow: 0px 0px 8px 2px white;
  }

  .dropdownTransition-enter-active,
  .dropdownTransition-leave-active{
    transition: all 0.3s ease;
  }

  .dropdownTransition-enter-from,
  .dropdownTransition-leave-to {
    transform: translateY(-100%);
    opacity: 0;
  }

  
  @media (max-width: 374px){
    #logoText{
      visibility: hidden;
      opacity: 0;
    }
  }

  @media (min-width: 768px){
    #logo{
      display: none;
    }
    #logoText{
      display: none;
    }
    #logoandText{
      display: inline-block;
    }
    .drawer{
      display: none;
    }
    #dropdownGroup{
      display: flex;
    }
  }

  #hexagon{
    height: 60px;
    width: 208px;
    margin-bottom: -2px;
    margin-left: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: #808080;
    cursor: pointer;
    clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
  }

  #hexagon_intern{
    height: 56px;
    width: 204px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: #f0f0f0;

    transition: all 0.1s;
    clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
  }
  #hexagon_intern:hover{
    background-color: #E5E5E5;
  }
  #hexagon_intern:active{
    background-color: #D8D8D8;
  }

  #infoVersion{
    /* background-color: gray; */
    width: 100%;
    text-align: center;
    /* position: absolute; */
    /* bottom: 0px; */
    margin-top: 50px;
    background-color: #eae9e5;
    border-top: 1px gray solid;
  }

</style>