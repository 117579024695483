<template>
  <div class="main">
    <div id="homeWrapper">
      <div class="optionsWrapper">
        <div class="char" @click="gotoCharacters()">
          <div class="imageChar">
          </div>
          <div class="containertext">
            <h2>Adventurers</h2>
            <p>	Features:</p>
            <p> &#9642; Adventurers Data</p>
            <p> &#9642; Adventurers Filter</p>
          </div>
        </div>
        <div class="assist" @click="gotoAssists()">
          <div class="imageAssist">
          </div>
          <div class="containertext">
            <h2>Assists</h2>
            <p>	Features:</p>
            <p> &#9642; Assists Data</p>
            <p> &#9642; Assists Filter</p>
          </div>
        </div>
        <div class="exped" @click="gotoDispatch()">
          <div class="imageDispatch">
          </div>
          <div class="containertext">
            <h2>Tools</h2>
            <p>	Features:</p>
            <p> &#9642; Dispatch Quest</p>
          </div>
        </div>
      </div>
      <div id="footerWrapper">
        <p>v1.0.0</p>
        <p>This website was made by Bravesa#6596</p>
        <p>Any feedback send me on discord or google forms.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { NButton } from 'naive-ui'
export default {
  name: "HomeView",
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  components: { NButton },
  beforeMount() {
    this.onResize()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth
    },
    gotoCharacters() {
      this.$router.push({ name: "adventurer_list" });
    },
    gotoAssists() {
      this.$router.push({ name: "assist_list" });
    },
    gotoDispatch() {
      this.$router.push({ name: "dispatch" });
    }
  },
};
</script>

<style>
.main {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-image: linear-gradient(135deg, #eae9e5 25%, #e0dfdb 25%, #e0dfdb 50%, #eae9e5 50%, #eae9e5 75%, #e0dfdb 75%, #e0dfdb 100%);
  background-size: 14px 14px;
  position: absolute;
  display: flex;
  justify-content: center;

  overflow: auto;
  /* flex-direction: column; */
}

#homeWrapper{
  height: fit-content;
  width: 100%;
  min-width: 356px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  /* background-color: gray; */
  flex-direction: column;
}

.optionsWrapper{
  position: relative;
  margin-top: 74px;
  height: fit-content;
  width: 100%;
  /* background-color: brown; */
  /* flex-direction: row; */
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 200px;
}

#itemwrapper{
  width: 50%;
}

#footerWrapper{
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 200px;
  background-color: #eae9e5;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
  text-align: center;
}

.char {
  width: 34%;
  /* max-width: 560px;*/
  min-width: 316px;
  max-height: 400px;
  min-height: 300px;
  height: 260px;
  margin: 60px auto 0px auto;
  border-radius: 5px;
  padding-bottom: 30px;
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.4);
  text-align: center;
  transition: 0.15s;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: 2px solid #484848;
}

.char:hover {
  box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.4);
}

.char:active {
  transform: translateY(2px);
}

.imageChar{
  height: 100%;
  width: 100%;
  background-image: url('../assets/memoria/characterslogo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.imageAssist{
  height: 100%;
  width: 100%;
  background-image: url('../assets/memoria/assistlogo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.imageDispatch{
  height: 100%;
  width: 100%;
  background-image: url('../assets/memoria/dispatch.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.containertext {
  color: black;
  /* font-style: bold; */
  font-size: 14px;
  line-height: 0.5;
  background: #e0dfdb;
  border-top: black 1px solid;
  position: absolute;
  /* margin: 0px auto; */
  width: 100%;
  height: 110px;
  bottom: 0px;
}

.containertext p{
  text-align: left;
  padding-left: 20px;
}

.assist {
  width: 34%;
  /* max-width: 560px;*/
  min-width: 316px;
  max-height: 400px;
  min-height: 300px;
  height: 260px;
  margin: 60px auto 0px auto;
  border-radius: 5px;
  padding-bottom: 30px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
  text-align: center;
  transition: 0.15s;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: 3px solid #484848 ;
}

.assist:hover {
  box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.4);
}

.assist:active {
  transform: translateY(2px);
}

.exped {
  width: 34%;
  /* max-width: 560px;*/
  min-width: 316px;
  max-height: 400px;
  min-height: 300px;
  height: 260px;
  margin: 60px auto 0px auto;
  border-radius: 5px;
  padding-bottom: 30px;
  /* background-color: #4c4c4c; */
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
  text-align: center;
  transition: 0.15s;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: 3px solid #484848 ;
}

.exped:hover {
  box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.4);
}

.exped:active {
  transform: translateY(2px);
}

.exped p {
  text-align: left;
  padding-left: 20px;
}
</style>
