<template>
  <div class="main_notfound">
    <div class="notfound_page">
      <div class="errorPage">
        <n-result status="error" title="Error" description="Not Found">
          <n-button @click="goHome">
            Go back to the main page.
          </n-button>
        </n-result>
      </div>
    </div>
  </div>
</template>

<script>

import {
  NButton,
  NResult
} from 'naive-ui'

export default {
  components:{
    NResult,
    NButton
  },
  methods: {
    goHome(){
      this.$router.push({ name: "home"});
    }
  },

}
</script>

<style>

.main_notfound{
  position: absolute;
  display: flex;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(135deg, #eae9e5 25%, #e0dfdb 25%, #e0dfdb 50%, #eae9e5 50%, #eae9e5 75%, #e0dfdb 75%, #e0dfdb 100%);
  background-size: 14px 14px;
  overflow: auto;
}

.notfound_page{
  margin-top: 120px;
  display: flex;
  width: 55%;
  height: 75%;
  max-width: 900px;
  min-width: 600px;
  box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 1);
  border-radius: 2px;
  background-color: #f7f7f7;
}

.errorPage{
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 80px;
  height: 100%;
  width: 100%;
  font-weight: bold;
}

</style>